$(document).ready(function() {

	// PARALLAX EFFECT

	$(window).scroll(function() {
		var e = $(this).scrollTop(),
			t = $("html").height();
		curTop = 800 * (e / t) + 0, $("#banners-scroll").css("top", curTop + 0 + "px")
	});


	// SLIDERS

	if ($('.slider_main_block').length) {

		$('.slider_main_block').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			dots: false,
			rtl: true,
			autoplay: true,
			pauseOnHover: false
		});

	}

	$('.about_wrap .slider').slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		rtl: true,
		autoplay: true,
		pauseOnHover: false,
		prevArrow: '.about_wrap .arrow_left',
		nextArrow: '.about_wrap .arrow_right',
		responsive: [{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 550,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

  $('.slider2').slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		rtl: true,
		autoplay: true,
		pauseOnHover: false,
		prevArrow: '.about_wrap .arrow_left',
		nextArrow: '.about_wrap .arrow_right',
		responsive: [{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 550,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

});